<template>
    <div class="row align-items-center align-content-center">
        <div class="col-12 mb-2 mb-md-0 col-md-4">
            {{((fromLanguages.length > 0) ? fromLanguages.join(', ') : $t('services.unknown'))}}
            <b-icon class="text-aspena-blue mx-2" :icon="(service.includeInverse) ? 'arrow-left-right' : 'arrow-right' "/>
            {{((toLanguages.length > 0) ? toLanguages.join(', ') : $t('services.unknown'))}}
        </div>
        <div class="col-12 mb-2 mb-md-0 col-md-5">
            {{services.join(', ')}}
        </div>
        <div class="col-12 col-md-3 text-left text-md-right whitespace-nowrap">
            <aspena-btn
                size="sm"
                variant="primary"
                class="mr-2"
                @click.prevent="onEdit"
                :hint="$t('buttonEditHint')"
            >
                <b-icon icon="pencil" />
            </aspena-btn>

            <aspena-btn
                size="sm"
                variant="danger"
                @click.prevent="onRemove"
                :hint="$t('buttonRemoveHint')"
            >
                <b-icon icon="trash" />
            </aspena-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesListGroupItem',
    props: {
        service: {
            type: Object,
            required: true
        }
    },
    computed: {
        languageOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.languageOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        serviceOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.serviceOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        services () {
            return this.service.services.map(item => {
                const enumEntry = this.serviceOptions.filter(entry => `${entry.value}` === `${item}`)
                if (enumEntry.length > 0) {
                    return enumEntry[0].text
                }
                return item
            })
        },
        fromLanguages () {
            return this.service.fromLanguages.map(item => {
                const enumEntry = this.languageOptions.filter(entry => `${entry.value}` === `${item}`)
                if (enumEntry.length > 0) {
                    return enumEntry[0].text
                }
                return item
            })
        },
        toLanguages () {
            return this.service.toLanguages.map(item => {
                const enumEntry = this.languageOptions.filter(entry => `${entry.value}` === `${item}`)
                if (enumEntry.length > 0) {
                    return enumEntry[0].text
                }
                return item
            })
        }
    },
    methods: {
        onRemove () {
            this.$emit('remove')
        },
        onEdit () {
            this.$emit('edit')
        }
    }
}
</script>
