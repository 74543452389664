<template>
    <b-form
        @submit.prevent="onSubmit"
        class="form-sm"
    >
        <h4 class="mb-4">{{$t('services.addTitle')}}</h4>
        <div class="row">
            <div class="col-12 mb-4">
                <multiselect-editor
                    v-model="fromLanguages"
                    :id="'services.fromLanguage'"
                    :label="$t('services.fromLanguage')"
                    :options="languageOptions"
                    :max-items="1"
                />
            </div>
            <div class="col-12 col-lg-12">
                <multiselect-editor
                    v-model="toLanguages"
                    :id="'services.toLanguage'"
                    :label="$t('services.toLanguage')"
                    :options="languageOptions"
                    :max-items="1"
                />
            </div>
            <div class="col-12 mt-4">
                <b-form-checkbox
                    v-model="includeInverse"
                >
                    {{$t('services.includeInverse')}}
                </b-form-checkbox>

                <multiselect-editor
                    class="mt-4"
                    v-model="services"
                    :id="'services.services'"
                    :label="$t('services.services')"
                    :validation="$v.services"
                    :options="serviceOptions"
                />
            </div>
        </div>

        <div class="mt-4 text-right">
            <aspena-btn
                class="mr-3"
                variant="aspena-orange"
                type="submit"
            >
                {{$t('services.confirmAdd')}}
            </aspena-btn>

            <aspena-btn
                variant="aspena-blue"
                @click.prevent="onCancel"
            >
                {{$t('services.clear')}}
            </aspena-btn>
        </div>
    </b-form>
</template>

<script>
import { Service } from '../../../DTO'
import { required } from 'vuelidate/lib/validators'
import MultiselectEditor from '../../PropertiesListsPage/Components/MultiselectEditor.vue'

export default {
    components: { MultiselectEditor },
    name: 'ServiceForm',
    props: {
        editedService: {
            type: Object,
            required: false,
            default: () => null
        }
    },
    data () {
        return {
            ...((new Service()).toObject()),
            search: null,
            fromLanguages: [],
            toLanguages: [],
            ...((this.editedService !== null) ? this.editedService : {})
        }
    },
    computed: {
        serviceOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.serviceOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        languageOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.languageOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        }
    },
    methods: {
        onSubmit () {
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('services.formInvalidMessage'))
                return this.$v.$touch()
            }
            const data = new Service(JSON.parse(JSON.stringify(this.$data)))
            this.$emit('submit', data)
            this.clear()
        },
        clear () {
            this.fromLanguages = []
            this.toLanguages = []
            this.includeInverse = false
            this.services = []
        },
        onCancel () {
            this.clear()
            this.$emit('cancel')
        }
    },
    validations: {
        services: {
            required
        }
    }
}
</script>

<style lang="scss">
.custom-checkbox label.custom-control-label {
    font-weight: 400;
    color: black;
}

.custom-checkbox + .custom-checkbox {
    margin-top: .35rem;
}
</style>
