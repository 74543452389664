<template>
    <div>
        <div class="mb-4 text-right">
            <aspena-btn
                variant="aspena-orange"
                @click.prevent="$emit('add')"
            >
                {{$t('services.addService')}}
            </aspena-btn>
        </div>

        <div v-show="value.length === 0" class="alert alert-primary text-center mb-5">
            {{$t('services.emptyServices')}}
        </div>

        <div class="mb-5">
            <div class="list-group services-list" v-show="value.length !== 0">
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-12 mb-2 mb-md-0 col-md-4">
                            <strong>{{$t('services.languagePair')}}</strong>
                        </div>
                        <div class="col-12 mb-2 mb-md-0 col-md-5">
                            <strong>{{$t('services.services')}}</strong>
                        </div>
                    </div>
                </div>
                <div class="list-group-item" v-for="(service, index) in services" :key="`service-${index}`">
                    <services-list-group-item
                        :service="service"
                        @edit="onEdit(index)"
                        @remove="onRemove(index)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ServicesListGroupItem from './ServicesListGroupItem.vue'

export default {
    name: 'ServicesListGroup',
    components: {
        ServicesListGroupItem
    },
    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        services: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onRemove (indexToRemove) {
            this.services = this.services.filter((item, index) => {
                return index !== indexToRemove
            })
            this.$emit('deleted', indexToRemove)
        },
        onEdit (indexToEdit) {
            this.$emit('edit', indexToEdit)
        }
    }
}
</script>

<style lang="scss">
.services-list .list-group-item {
    letter-spacing: unset;
    font-weight: 400;
    text-transform: none;
}
</style>
