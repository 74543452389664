<template>
    <div class="container">
        <slot name="registrationType"></slot>
        <div v-if="showServiceForm">
            <h1 class="mt-0">{{$t('services.title')}}</h1>
            <p class="mb-5">{{ $t('services.subtitle') }}</p>
        </div>
        <div v-else>
            <h1 class="mt-0">{{ $t('services.title2') }}</h1>
            <p class="mb-5">{{ $t('services.subtitle2') }}</p>
        </div>
        <services-list-group
            v-model="services"
            @add="onAddService"
            @edit="onEdit"
            @deleted="onFormCancel"
            v-if="!showServiceForm"
        />

        <div v-show="!showServiceForm">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-sm">
                        <form-input-checkbox-group
                            v-model="discount"
                            :id="'services.discount'"
                            :label="$t('services.discount')"
                            :options="discountOptions"
                        />
                    </div>
                </div>
            </div>

            <div class="my-4 text-right">
                <aspena-btn
                    class="mr-3"
                    variant="aspena-blue"
                    @click.prevent="onCancel"
                >
                    {{$t('services.cancel')}}
                </aspena-btn>

                <aspena-btn
                    class="mr-3"
                    variant="aspena-orange"
                    @click.prevent="onSubmit"
                    :disabled="services.length === 0"
                >
                    {{$t('services.submit')}}
                </aspena-btn>
            </div>
        </div>

        <div class="p-4 bg-aspena-light-gray" v-if="showServiceForm">
            <service-form
                :edited-service="editedService"
                @submit="onServiceSubmit"
                @cancel="onFormCancel"
            />
        </div>
    </div>
</template>

<script>
import { ServicesList } from '../../DTO'
import ServiceForm from './Components/ServiceForm.vue'
import ServicesListGroup from './Components/ServicesListGroup.vue'

export default {
    name: 'ServicesPage',
    components: {
        ServiceForm,
        ServicesListGroup
    },
    data () {
        return {
            showServiceForm: false,
            editedIndex: null
        }
    },
    computed: {
        editedService: {
            get () {
                return (this.editedIndex !== null && this.servicesList.services[this.editedIndex]) ? this.servicesList.services[this.editedIndex] : null
            },
            set (value) {
                const list = JSON.parse(JSON.stringify(this.servicesList))
                list.services = list.services.map((item, index) => {
                    return (index === this.editedIndex) ? value : item
                })
                this.servicesList = list
            }
        },
        servicesList: {
            get () {
                return (new ServicesList(JSON.parse(JSON.stringify(this.$store.state.userData.servicesList || {})))).toObject()
            },
            set (value) {
                const data = new ServicesList(JSON.parse(JSON.stringify(value)))
                this.$store.commit('mergeData', data)
            }
        },
        discountOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.discountOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        discount: {
            get () {
                const state = this.$store.state.userData.servicesList
                return (state) ? state.discount || [] : []
            },
            set (value) {
                this.servicesList = { ...this.servicesList, discount: value }
            }
        },
        note: {
            get () {
                const state = this.$store.state.userData.servicesList
                return (state) ? state.note || null : null
            },
            set (value) {
                this.servicesList = { ...this.servicesList, note: value }
            }
        },
        services: {
            get () {
                return this.servicesList.services
            },
            set (value) {
                this.servicesList = { ...this.servicesList, services: value }
            }
        }
    },
    mounted () {
        if (this.services.length === 0) {
            this.showServiceForm = true
        }
        this.$nextTick(() => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 200)
        })
    },
    methods: {
        onEdit (index) {
            this.showServiceForm = false
            this.editedIndex = null
            this.$nextTick(() => {
                this.editedIndex = index
                this.showServiceForm = true
            })
        },
        onAddService () {
            this.showServiceForm = false
            this.$nextTick(() => {
                this.showServiceForm = true
            })
        },
        onServiceSubmit (service) {
            if (this.editedService !== null) {
                this.editedService = service
            } else {
                const list = this.servicesList
                list.services.push(service)
                this.servicesList = list
            }

            this.$nextTick(() => {
                this.editedIndex = null
                this.showServiceForm = false
            })
        },
        onSubmit () {
            this.$router.push({ name: 'propertyLists' })
        },
        onFormCancel () {
            this.showServiceForm = false
            this.editedIndex = null
        },
        onCancel () {
            this.$router.push({ name: 'headquartersAddress' })
        }
    }
}
</script>

<style lang="scss">
    legend.col-form-label {
        margin-bottom: .4rem;
        color: #00377c;
        font-weight: 700;
        display: block;
        padding-bottom: 0;
    }
</style>
